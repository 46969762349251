import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/mainLayout/MainLayout.jsx";
import "./contacts/contact.scss";
import { ContactForm } from "../components/contactForm/ContactForm.jsx";

const IndexPageV2 = ({ data }) => {
  return (
    <Layout withBackground>
      <div className="contacts">
        <div className="contacts__frame">
          <h1>Parašykite mums</h1>
          <p>
            Turite klausimų, atsiliepimų ar norite tik pasakyti labas,
            užpildykite formą žemiau ir mes su jumis susisieksime.
          </p>
          <div className="contacts__form-wrapper">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPageV2;

export const pageQuery = graphql`
  query ContactsTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contacts" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`;
