import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FormField, FormButton, FormTextarea } from "../form";
import "./contact-form.scss";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Įveskite savo vardą."),
  email: Yup.string()
    .required("Įveskite el. pašto adresą.")
    .email("Įveskite teisingą el. pašto adresą."),
});

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const ContactForm = () => {
  const formId = "contact-form";
  const STATUSES = {
    READY: "READY",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    HAS_ERRORS: "HAS_ERRORS",
  };

  const [status, setStatus] = useState(STATUSES.READY);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      "sure-name": "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setStatus(STATUSES.IN_PROGRESS);
      const formData = values;

      formData["form-name"] = formId;

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      }).then(() => {
        setTimeout(() => {
          setStatus(STATUSES.COMPLETED);
        }, 1000);
      });
    },
  });

  return (
    <div className="form">
      {status === STATUSES.HAS_ERRORS && (
        <div className="form__errors">
          <strong>
            Nepavyko išsiųsti registracijos. <br />
            Bandykite dar kartą vėliau.
          </strong>
        </div>
      )}
      {status !== STATUSES.COMPLETED && (
        <div>
          <form
            method="POST"
            onSubmit={formik.handleSubmit}
            name={formId}
            netlify="true"
            netlify-honeypot="sure-name"
            data-netlify="true"
            data-netlify-honeypot="sure-name"
          >
            <input type="hidden" name="form-name" value={formId} />
            <div className="sure-name">
              <label>
                Don&apos;t fill this out if you&apos;re human:
                <input
                  name="sure-name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>
            </div>
            <FormField
              name="name"
              label="Vardas"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={
                status === STATUSES.IN_PROGRESS || status === STATUSES.COMPLETED
              }
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />
            <FormField
              name="phone"
              label="Telefonas"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={
                status === STATUSES.IN_PROGRESS || status === STATUSES.COMPLETED
              }
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null
              }
            />
            <FormField
              name="email"
              label="El. paštas"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={
                status === STATUSES.IN_PROGRESS || status === STATUSES.COMPLETED
              }
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            <FormTextarea
              name="message"
              label="Žinutė"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={
                status === STATUSES.IN_PROGRESS || status === STATUSES.COMPLETED
              }
              error={
                formik.touched.message && formik.errors.message
                  ? formik.errors.message
                  : null
              }
            />
            <div className="form__field-wrapper">
              <FormButton
                type="submit"
                disabled={
                  status === STATUSES.COMPLETED ||
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
              >
                {status === STATUSES.IN_PROGRESS
                  ? "Siunčiam žinutę.."
                  : "Siųsti"}
              </FormButton>
            </div>
          </form>
        </div>
      )}
      {status === STATUSES.COMPLETED && (
        <div>
          <h2 className="form__message-title">Ačiū už jūsų užklausą!</h2>
          <p className="form__message-text">
            Jūsų žinutę gavome ir atsakysime į ją artimiausiu metu.
          </p>
        </div>
      )}
    </div>
  );
};
